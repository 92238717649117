import award0 from './images/award0.svg';
import award1 from './images/award1.png';
import award2 from './images/award2.png';
import award3 from './images/award3.png';
import award4 from './images/award4.png';
import award5 from './images/award5.png';
import award6 from './images/award6.png';
import award7 from './images/award7.png';
import award8 from './images/award8.png';
import award9 from './images/award9.png';
import award10 from './images/award9.png';
import award11 from './images/award10.png';
import award12 from './images/award11.png';

import styles from './Awards.module.scss';

const AWARDS = [
  {
    image: award0,
    title: 'Remote Monitoring Security Solution of the Year',
  },
  {
    image: award6,
    title: 'The National Cyber Awards AI product of the year 2023',
  },
  {
    image: award1,
    title: [
      'Winner of SigWiComp by the German Research Center for AI',
    ].join(' '),
  },
  {
    image: award9,
    title: 'BOLD Awards 2024 Boldest Healthtech',
    className: styles.Award9,
  },
  {
    image: award10,
    title: 'BOLD Awards 2024 Boldest Cybersecurity',
    className: styles.Award9,
  },
  {
    image: award11,
    title: 'Go:Tech Awards AI/Machine Learning',
    className: styles.Award9,
  },
  {
    image: award7,
    title: 'Informatics in Medicine Unlocked - Detection of mild cognitive impairment based on mouse movement data',
    link: 'https://www.sciencedirect.com/science/article/pii/S235291482200257X',
    className: styles.Award7,
  },
  {
    image: award2,
    title: [
      'Winner of the Mouse Dynamics Challenge competition of Balabit',
    ].join(' '),
  },
  {
    image: award3,
    title: 'Top 50 UK startups',
    className: styles.Award3,
  },
  {
    image: award4,
    title: 'Top 10 CEE Scale-up by Vestbee',
    className: styles.Award4,
  },
  {
    image: award5,
    title: 'Digital Alliance Prize by the Ministry of Technology and Innovation',
  },
  {
    image: award8,
    title: 'Feature space reduction method for ultrahigh-dimensional, multiclass data: RFMS',
    link: 'https://iopscience.iop.org/journal/2632-2153',
  },
  {
    image: award7,
    title: 'SoftwareX - BiometricBlender: Ultra-high dimensional, multi-class synthetic data generator to imitate biometric feature space',
    link: 'https://www.softxjournal.com/article/S2352-7110(23)00062-6/fulltext',
  },
  {
    image: award12,
    title: 'The National Cyber Awards AI product of the year 2024',
  },
];

export default AWARDS;
